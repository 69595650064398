<script>
import CardToolbar from "@/common/components/CardToolbar.vue";
import { onMounted, ref } from "vue";
import {
  formatNumberIntoHumanizeForm,
  paginate,
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import { getAllCurators, saveCuratorsToHubspot } from "@/api/curators.api";
import { ElNotification } from "element-plus";
import { TypeEnums } from "@/common/enums/typeEnums";
import { getExpertsFromRocketReach } from "@/api/rocketReach.api";
import { Search } from "@element-plus/icons-vue";

export default {
  name: "Curators",
  computed: {
    Search() {
      return Search;
    },
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: {
    tableRowClassName,
    tableCellClassName,
    routeToPage,
    formatNumberIntoHumanizeForm,
  },
  components: {
    CardToolbar,
  },
  setup() {
    const loading = ref(false);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tableDataWithOutPagination = ref([]);
    const selectedCurators = ref([]);
    const paginationObj = ref({
      offset: 1,
      limit: 20,
      total: 0,
      sortBy: "playlistReach",
      sortOrder: "desc",
    });
    const allSelected = ref(false);
    const expertSearchKey = ref(null);
    const experts = ref([]);
    const expertOffset = ref(1);
    const expertLimit = ref(30);
    const expertsLoading = ref(false);
    onMounted(async () => {
      document.title = "Curators";
      await getCuratorsTableData();
    });

    const selectAllClicked = (value) => {
      if (value) {
        selectedCurators.value = tableData.value.map(
          (object) => object.chartmetricCuratorID
        );
      } else {
        selectedCurators.value = [];
      }
    };

    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedCurators.value.includes(id)) {
        selectedCurators.value.push(id);
      } else if (!event) {
        const index = selectedCurators.value.indexOf(id);
        if (index > -1) {
          selectedCurators.value.splice(index, 1);
        }
      }
    };

    const getCuratorsTableData = async () => {
      try {
        loading.value = true;
        const { data } = await getAllCurators();
        tableDataWithOutPagination.value = data && data.data ? data.data : [];
        paginationObj.value.total = tableDataWithOutPagination.value.length;
        paginationChanged();
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching Curators",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const sortResults = ({ prop, order }) => {
      loading.value = true;
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithOutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithOutPagination.value))
        );
      }
      paginationChanged();
      loading.value = false;
    };

    const paginationChanged = () => {
      tableData.value = paginate(
        tableDataWithOutPagination.value,
        paginationObj.value.limit,
        paginationObj.value.offset
      );
    };

    const addToCrm = () => {
      try {
        saveCuratorsToHubspot({
          chartmetricCuratorIDs: selectedCurators.value,
        });

        selectedCurators.value = [];
        allSelected.value = false;

        ElNotification({
          title: "Success!",
          message: "Curators added successfully",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in adding curators to hubspot",
          type: "error",
        });
      }
    };

    const getExperts = async () => {
      try {
        expertsLoading.value = true;
        const { data } = await getExpertsFromRocketReach(
          expertSearchKey.value,
          expertOffset.value,
          expertLimit.value
        );
        experts.value = data && data.data ? data.data : [];
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Search Limit Exceeds!",
          type: "error",
        });
      } finally {
        expertsLoading.value = false;
      }
    };

    return {
      loading,
      tableData,
      paginationObj,
      allSelected,
      selectedCurators,
      experts,
      expertSearchKey,
      expertsLoading,
      selectAllClicked,
      paginationChanged,
      sortResults,
      checkBoxValueChanged,
      addToCrm,
      getExperts,
    };
  },
};
</script>
<template>
  <div class="d-flex page-heading">Curators</div>
  <CardToolbar
    title=""
    :show-toolbar-buttons="true"
    :margin="true"
    :fontStyle="true"
    :padding="false"
    :padding-left="false"
    :shadow-class="true"
    :show-header="true"
  >
    <template v-slot:toolbar>
      <el-checkbox
        style="margin-right: 1rem"
        :disabled="tableData.length === 0"
        v-model="allSelected"
        @change="selectAllClicked"
        label="Select all on page"
        size="large"
      ></el-checkbox>
      <button
        @click="addToCrm"
        :disabled="selectedCurators.length === 0"
        class="
          btn-outline btn-outline-primary btn-active-light-primary btn-sm btn
          cursor-pointer
          me-2
          mb-2
        "
      >
        +CRM
      </button>
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        v-loading="loading"
        :fit="true"
        size="medium"
        :data="tableData"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column label="Select" width="80" fixed>
          <template #default="scope">
            <el-checkbox
              :model-value="
                selectedCurators.includes(scope.row.chartmetricCuratorID)
              "
              @change="
                checkBoxValueChanged($event, scope.row.chartmetricCuratorID)
              "
              size="large"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          class-name="cursor-pointer"
          prop="name"
          label="Owner Name"
          sortable="custom"
          fixed
          width="300"
        >
          <template #default="scope">
            <p
              @click="
                routeToPage(
                  `curator-detail/${scope.row.chartmetricCuratorID}`,
                  TypeEnums.CURATOR,
                  scope.row.chartmetricCuratorID
                )
              "
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.name ? scope.row.name : 'N/A'"
            >
              {{ scope.row.name ? scope.row.name : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="playlistReach"
          label="Playlist Reach"
          sortable="custom"
          width="200"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.playlistReach
                  ? formatNumberIntoHumanizeForm(scope.row.playlistReach)
                  : 0
              "
            >
              {{
                scope.row.playlistReach
                  ? formatNumberIntoHumanizeForm(scope.row.playlistReach)
                  : 0
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="followers"
          label="Followers"
          sortable="custom"
          width="200"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.followers
                  ? formatNumberIntoHumanizeForm(scope.row.followers)
                  : 0
              "
            >
              {{
                scope.row.followers
                  ? formatNumberIntoHumanizeForm(scope.row.followers)
                  : 0
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="maxPlaylistReach"
          label="Max Playlist Reach"
          sortable="custom"
          width="220"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.maxPlaylistReach
                  ? formatNumberIntoHumanizeForm(scope.row.maxPlaylistReach)
                  : 0
              "
            >
              {{
                scope.row.maxPlaylistReach
                  ? formatNumberIntoHumanizeForm(scope.row.maxPlaylistReach)
                  : 0
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="playlists"
          label="Playlists"
          sortable="custom"
          width="200"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.playlists
                  ? formatNumberIntoHumanizeForm(scope.row.playlists)
                  : 0
              "
            >
              {{
                scope.row.playlists
                  ? formatNumberIntoHumanizeForm(scope.row.playlists)
                  : 0
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="Genres" width="300">
          <template #default="scope">
            <el-tag
              v-for="item in scope.row.tags"
              :key="item"
              size="small"
              class="mx-1"
              effect="dark"
            >
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="paginationObj.offset"
        v-model:page-size="paginationObj.limit"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="paginationObj.limit"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="paginationObj.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <div class="d-flex mb-5">
    <el-input
      v-model="expertSearchKey"
      size="large"
      placeholder="Search Experts"
      :suffix-icon="Search"
    />
    <button
      @click="getExperts"
      :disabled="!expertSearchKey"
      class="ms-8 btn btn-sm btn-primary btn-style ps-16 pe-16 pt-1 pb-1"
      style="border-radius: 4px"
    >
      Search
    </button>
  </div>

  <CardToolbar
    v-loading="expertsLoading"
    title="Strong Match Experts"
    :show-toolbar-buttons="false"
    :margin="true"
    :fontStyle="true"
    :padding="false"
    :padding-left="false"
    :shadow-class="true"
    :show-header="true"
  >
    <template v-slot:body>
      <div class="row">
        <div
          class="col-3 mt-2 mb-2"
          v-for="(expert, index) in experts"
          :key="index"
        >
          <div
            class="card box-card m-8 w-70 shadow bg-white rounded"
            style="position: relative"
          >
            <div
              class="
                card-header
                expert-card-header-border
                justify-content-center
                align-items-center
              "
            >
              <div class="expert-avatar">
                <el-avatar :size="100">
                  <img
                    :src="
                      expert && expert.profile_pic
                        ? expert.profile_pic
                        : '/media/avatars/blank.png'
                    "
                    alt="expert-img"
                  />
                </el-avatar>
              </div>
              <div class="expert-name pt-lg-4">
                <h6 class="theme-v2-color fw-bolder">
                  {{ expert && expert.name ? expert.name : "Expert Name" }}
                </h6>
              </div>
            </div>
            <div class="row expert-card-subheading">
              <p>
                <span class="text-primary">{{
                  expert && expert.country ? expert.country : "Expert Country"
                }}</span>
                <br />
                <span class="theme-v2-color fw-bold">{{
                  expert && expert.current_title
                    ? expert.current_title
                    : "Title"
                }}</span>
              </p>
            </div>
            <div class="row">
              <div
                class="col-6 card-icons"
                style="border-right: 1px solid #eff2f5"
              >
                <el-button type="text" class="button">
                  <a
                    target="_blank"
                    :href="
                      expert && expert.linkedin_url ? expert.linkedin_url : ''
                    "
                  >
                    <i class="bi bi-linkedin text-primary expert-icon"></i>
                  </a>
                </el-button>
              </div>
              <div class="col-6 card-icons">
                <el-button type="text" class="button">
                  <!--                    <i :class="['text-primary expert-icon', (expert.favorite === true)? 'bi bi-heart-fill' : 'bi bi-heart']"></i>-->
                  <i :class="['text-primary expert-icon bi bi-heart']"></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CardToolbar>
</template>
<style scoped>
.expert-description {
  font-size: 12px;
}
.card-color {
  background-color: aliceblue !important;
}
.strong-match-experts-card-header {
  display: contents !important;
}
.expert-header {
  border-bottom: none !important;
}
.expert-name {
  min-height: 30px;
  padding: 0 2.25rem;
  padding-top: 6rem !important;
}
.expert-card-subheading {
  text-align: center !important;
  border-bottom: 1px solid #eff2f5;
}
.card-icons {
  text-align: center !important;
}
.expert-icon {
  font-size: 24px;
}
.expert-avatar {
  position: absolute;
  bottom: 65%;
}
.expert-card-header-border {
  border-bottom: none !important;
}
</style>
