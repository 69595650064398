import httpClient from "@/api/httpClient";

const RocketReach = "/rocket-reach";

const getExpertsFromRocketReach = (searchKey, offset, limit) =>
  httpClient.get(
    `${RocketReach}/search?current_title=${searchKey}&offset=${offset}&limit=${limit}`
  );

export { getExpertsFromRocketReach };
